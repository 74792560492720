.relatedNews {
  background: transparent;
  margin-top: -430px;
  &::before {
    display: none;
  }
}
.relatedNewsTitle {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black;
}
.postContent {
  background: #eeeeee;
  padding-bottom: 500px;
  .center {
    display: flex;
    justify-content: space-between;
  }
}
.postContentLeft {
  background: $white;
  padding: 100px 60px;
  width: 55%;

  &__Title {
    margin: 0 0 30px 0;
    font-family: $title;
    font-size: 44px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $black;
    span {
      color: $brandRed;
    }
  }
  p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $black;
  }
  img {
    vertical-align: middle;
    width: calc(100% + 160px);
    height: auto;
    margin-left: -80px;
  }
}
.postContentRight {
  padding-top: 125px;
  width: 35%;
}
.postAuthor {
  h3 {
    font-family: $title;
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    margin: 0;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandRed;
    margin: 0;
    margin-bottom: 60px;
  }
  h6 {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
    margin-right: 5px;
  }
}
.postAuthorImg {
  width: 170px;
  height: 170px;
  border-radius: 100%;
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;
  img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:cover;
    left: 0;
  }
}