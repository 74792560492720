.referencePage {
  .leftTitleTop {
    top: 210px;
  }
}
.references {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
}
.referencesFilter {
  margin-bottom: 80px;
  border-bottom: 1px solid $white;
  li {
    padding-bottom: 40px;
  }
  span {
    font-family: $title;
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
    cursor: pointer;
    transition:300ms;
    padding-bottom: 40px;
    position: relative;
    white-space: nowrap;
    &:before {
      content: '';
      position: absolute;
      bottom: -1.5px;
      left: 0;
      right: 0;
      height: 3px;
      background: $brandGreen;
      opacity: 0;
      transition:300ms;
    }
    &:hover {
      color: $brandGreen;
      &:before {
        opacity: 1;
      }
    }
  }
  .slick-list {
    overflow: visible !important;
  }
  .slick-track {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 !important;
    &::before {
      display: none !important;
    }
  }
  .slick-slide {
    max-width: auto !important;
  }
  .slick-current {
    span {
      color: $brandGreen;
      &:before {
        opacity: 1;
      }
    }
  }
}
.reference {
  width: 30%;
  padding: 30px;
  background-image: linear-gradient(329deg, #000000, #1a1a1a);
  margin-bottom: 90px;
  min-height: 170px;
  h3 {
    font-family: $title;
    font-size: 24px;
    font-weight:lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: $white;
    margin: 0;
    text-transform: uppercase;
    transition:300ms;
  }
  &:hover {
    h3 {
      color: $brandGreen;
    }
  }
}


.clients {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 80px;
  border-top: 1px solid $white;
}

.client {
  width: 23%;
  padding: 30px;
  background-image: linear-gradient(329deg, #000000, #1a1a1a);
  margin-bottom: 2%;
  height: 130px;
  position: relative;
  h3 {
    font-family: $title;
    font-size: 24px;
    font-weight:lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: $white;
    margin: 0;
    text-transform: uppercase;
    transition:300ms;
  }
  /*&:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: $brandRed;
    border-radius: 100%;
    right: -25px;
    top: 50%;
    transform:translateY(-50%);
  }
  &:nth-of-type(4n) {
    &:before {
      display: none;
    }
  }*/
  &:hover {
    h3 {
      color: $brandGreen;
    }
  }
}