.siteHeader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 120px;
  background: $white;
  display: flex;
  justify-content: space-between;
  z-index: 100000;
}
.siteHeaderLogo {
  background: $black;
  width: 160px;
  top: 0;
  left: 0;
  z-index: 10;
  position: relative;
  display: block;
  height: 120px;
  text-align: center;
  svg {
    position: relative;
    max-width: 130px;
    top: 47%;
    transform: translateY(-50%);
  }
  a {
    display: block;
    height: 120px;
  }
}
.langSelector {
  width: 160px;
  height: 120px;
  z-index: 10;
  position: relative;
  top: 0;
  right: 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  li {
    display: inline-block;
  }
  .wpml-ls-current-language {
    a {
      color: $brandRed;
      font-size: 18px;
    }
  }
  a {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: $black;
    text-transform: uppercase;
    transition:300ms;
    position: relative;
    &:hover {
      color: $brandRed;
    }
  }
}
.mainNav {
  max-width: 1100px;
  width: 100%;
  position: relative;
  text-align: center;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  li {
    display: inline-block;
    ul {
      position: absolute;
      top: 0;
      transform: translateY(-500px);
      padding-top: 90px;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: opacity 0.4s;
      -moz-transition: opacity 0.4s;
      -o-transition: opacity 0.4s;
      transition: opacity 0.4s;
      text-align: left;
      li {
        display: block;
        margin-bottom: 20px;
        a {
          font-size: 17px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: -100vw;
        right: -100vw;
        top: 0;
        min-height: 270px;
        background: $white;
        z-index: -1;
      }
    }
    &:hover {
      ul {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  a {
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    text-transform: uppercase;
    font-family: $title;
    padding-left: 30px;
    padding-right: 30px;
    transition:300ms;
    z-index: 10;
    position: relative;
    &:hover {
      color: $brandRed;
    }
  }
  .current-menu-item {
    a {
      color: $brandRed;
    }
  }
}
.mobileNav {
  display: none;
}