.similarReferences {
  h2 {
    font-family: $title;
    margin: 0;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
  }
  .references {
    padding: 50px 0;
    border: 1px solid $white;
    border-left: 0;
    border-right: 0;
    margin-bottom: 80px;
  }

  .moreProjects {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-family: $title;
      font-size: 24px;
      font-weight: lighter;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $brandRed;
      text-transform: uppercase;
      margin-right: 30px;
      transition:300ms;
    }
    svg {
      path {
        transition:300ms;
      }
    }
    &:hover {
      span {
        color: $brandGreen;
      }
      svg {
        path {
          fill: $brandGreen;
        }
      }
    }
  }
}
.singleProjectPage {
  background: $black;
  position: relative;
  padding-bottom: 400px;
  .leftTitleTop {
    top: 300px;
  }
}
.singleProject {
  display: flex;
  justify-content: space-between;
  margin-bottom: 140px;
}
.singleProjectLeft, .singleProjectRight {
  width: 45%;
}
.singleProjectLeft {
  padding-top: 90px;
  h4 {
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $white;
  }
  p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $white;
  }
  ul {
    list-style: none;
  }
  li {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $white;
  }
  a {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $brandRed;
    transition: 300ms;
    &:hover {
      color: $brandGreen;
    }
  }
}
.singleProjectRight {
  padding: 90px;
  padding-right: 0;
  z-index: 1;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% + 100vw);
    background-image: linear-gradient(314deg, #000000, #1a1a1a);
    z-index: -1;
  }
  img {
    vertical-align: top;
    max-width: 100%;
    margin-bottom: 40px;
  }
  a {
    font-family: $title;
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandRed;
    transition: 300ms;
    &:hover {
      color: $brandGreen;
    }
  }
}
.map {
  height: 90vh;
  width: 55%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    z-index: 1;
  }
}
.contactPageSection {
  .singleProjectLeft, .singleProjectRight {
    height: 90vh;
    background: transparent;
  }
}