.pageIntro {
  padding-top: 450px;
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  
  h2 {
    margin: 0;
    font-family: $title;
    font-size: 64px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $white;
    span {
      color: $brandRed;
    }
  }
}

.teamPage {
  padding: 140px 0 310px 0;
  background: $black;
  position: relative;
  .rightTitleTop {
    top: 150vh;
  }
}
.teamLeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 220px;
}
.teamLeaderLeft {
  width: 30%;
  h4 {
    margin: 0;
    margin-bottom: 40px;
    font-family: $title;
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandRed;
  }
  p {
    max-width: 210px;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #828282;
    transition:300ms;
  }
  a {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #828282;
    transition:300ms;
    &:hover {
      color: $brandGreen;
    }
  }
}
.teamLeaderRight {
  width: 65%;
  h2 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 44px;
    font-family: $title;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: $white;
    }
  }
  h6 {
    font-size: 26px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    margin: 0;
    margin-bottom: 120px;
  }
  h5 {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    margin: 0;
    transition:300ms;
    a {
      text-decoration: none;
      color: $brandGreen;
      transition:300ms;
    }
    &:hover {
      a {
        color: $white;
      }
    }
  }
  p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $white;
  }
}
.teamLeaderImg {
  width: 210px;
  height: 210px;
  border-radius: 100%;
  position: relative;
  margin-bottom: 80px;
  overflow: hidden;
  img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:cover;
    left: 0;
  }
}
.people {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.person {
  width: 30%;
  padding: 30px;
  background-image: linear-gradient(329deg, #000000, #1a1a1a);
  margin-bottom: 190px;
  h3 {
    font-family: $title;
    font-size: 24px;
    font-weight:lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: $white;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  h6 {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    margin: 0;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $white;
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    color: #828282;
    transition:300ms;
    &:hover {
      color: $brandGreen;
    }
  }
  span {
    color: #828282;
  }
  h5 {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    margin: 0;
    transition:300ms;
    a {
      text-decoration: none;
      color: $brandGreen;
    }
    &:hover {
      a {
        color: $white;
      }
    }
  }
}

.personImg {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  position: relative;
  border: 1px solid $white;
  overflow: hidden;
  margin-top: -90px;
  margin-bottom: 30px;
  img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:cover;
    left: 0;
  }
}