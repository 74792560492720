.redButton {
  align-self: flex-end;
  height: 80px;
  width: 400px;
  background-image: linear-gradient(90deg, #b71010, #e74c4c);
  display: block;
  position: relative;
  font-size: 30px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: 75px;
  text-align: center;
  letter-spacing: normal;
  color: $white;
  font-family: $title;
  text-transform: uppercase;
  margin: 50px auto 0 auto;
  padding: 0 30px;
}

.buttonsList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 40px 0 0 0;

  li {
    //width: 30%;
    margin: 0 1% 2% 1%;
  }

  .redButton {
    width: 100%;
    align-self: initial;
    margin: 0;
  }
}

.defaultPageIntro {
  padding-top: 450px;
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .center {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    margin: 0;
    font-family: $title;
    font-size: 64px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $white;
  }

  a {
    margin: 0;
    top: -20px;
  }
}

.defaultPageSection {
  padding-bottom: 500px;
}

.contactPageSection {
  padding-bottom: 150px;
}

.relatedServices {
  margin-bottom: 160px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  h2 {
    font-family: $title;
    margin: 0;
    margin-bottom: 150px;
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    width: 100%;
  }
}

.relatedService {
  width: 47%;
  display: flex;
  justify-content: space-between;
}

.relatedServiceImg {
  margin-top: -75px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0;
  }
}

.relatedServices.companies,
.relatedServices.tvrtke {
  .relatedService {
    width: 31%;
  }

  .relatedServiceImg {
    margin-top: -35px;
    width: 100px;
    height: 100px;
  }
}

.relatedServiceCnt {
  width: 70%;

  h3 {
    margin: 0;
    font-size: 26px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.78;
    letter-spacing: normal;
    color: $white;
  }
}