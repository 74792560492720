@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700&subset=latin-ext');

@font-face{
  font-family:"Bank Gothic";
  src: url("../fonts/BankGothicLtBTLight.ttf") format("truetype");
  font-weight:  lighter;
  font-style:   normal;
  font-stretch: normal;
}
@font-face{
  font-family:"Bank Gothic";
  src: url("../fonts/BankGothicMdBT.ttf") format("truetype");
  font-weight:  bold;
  font-style:   normal;
  font-stretch: normal;
}
*{
  box-sizing: border-box;
}
body, html {-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}

body{
  overflow-x: hidden;
}

body, html{
  @include media('<=tablet'){
    width: 100%;
    height: 100%;
  }
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
#ccc {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
}

body, html, ul, ol{
  padding: 0;
  margin: 0;
}

button{
  padding: 0;
  outline: 0;
}

a{
  text-decoration: none;
}

body, html {-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}

.center{
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}
