.newsPageIntro {
  padding-top: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .center {
    padding: 80px 0;
    z-index: 1;
    height: 320px;
    margin-bottom: -160px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: calc(100% + 100vw);
      background-image: linear-gradient(90deg, #000000, #1a1a1a);
      z-index: -1;
    }
  }
  h2 {
    margin: 0;
    font-family: $title;
    font-size: 64px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $white;
  }
}
.paginationWrap {
    width: 100%;
    margin-bottom: 100px;
    height: 50px;
  .buttonMore {
    a {
      display: flex;
      align-items: center;
      height: 50px; 
    }
  }
  .center-load {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $black;
    width: 100%;
  }
}
.feedWrap {
  h4.newsFeedTitle {
    margin: 0;
    margin-bottom: 20px;
    font-size: 44px;
    font-family: $title;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandRed;
    position: relative;
    z-index: 1;
  }
  .yotu-video-title {
    font-family: $title;
    font-size: 24px !important;
    font-weight: lighter !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black !important;
    margin: 0 !important;
    margin-bottom: 30px !important;
  }
  .yotu-videos {
    a:hover {
      h3 {
        text-decoration: none !important;
        color: $brandRed !important;
      }
    }
  }
}
.yotu-copyright {
  display: none !important;
}
.yotu-button-prs {
  height: 80px !important;
  width: 400px !important;
  background-image: linear-gradient(90deg, #b71010, #e74c4c) !important;
  display: block !important;
  position: relative !important;
  font-size: 30px !important;
  font-weight: lighter !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 75px !important;
  text-align: center !important;
  letter-spacing: normal !important;
  color: $white !important;
  font-family: $title !important;
  text-transform: uppercase !important;
  border: 0 !important;
  margin: 0 auto !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}