.intro {
  background-color: $black;
  margin-top: 120px;
  padding-top: 210px;
  /*height: 1700px;*/
}

.videoContainer {
  position:absolute;
  top: 120px;
  height: 100vh;
  width:100%;
  overflow: hidden;
  z-index:0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  video{
    width: 100%; 
    height: auto;
  }
  &:before {
    content: '';
    position: absolute;
    top: 75%;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  }
}
.introTop {
  display: flex;
  padding-bottom: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  h6 {
    font-family: $title;
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 6.7px;
    color: $white;
    span {
      color: $brandRed;
      font-family: $default;
      font-weight:bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px;
    }
  }
  h2 {
    font-size: 54px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $white;
    margin: 0;
  }
}
.introTopInner {
  max-width: 1280px;
  /*width: calc(100% - 240px);*/
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include media('<=1024px'){
    display: block;
  }
}
.introTopCta {
  /*bottom: -100px;*/
  top: 225px;
  position: relative;
  min-width: 240px;
  max-width: 240px;
  height: 100px;
  /*align-self: flex-end;*/
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% + 100vw);
    background-image: linear-gradient(90deg, #b71010, #e74c4c);
    z-index: -1;
  }
  a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    font-family: $title;
    text-transform: uppercase;
    padding-left: 30px;
  }
}
.introBottom {
  position: relative;
  .center {
    position: relative;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 90px;
    /*&::before {
      content: '';
      position: absolute;
      top: 0;
      right: 240px;
      bottom: 0;
      width: calc(100% + 100vw);
      background-image: linear-gradient(0deg, rgba(26, 26, 26, 0), #000000);
      z-index: -1;
      opacity: 0.7;
    }*/
  }
  h4 {
    margin: 0;
    /*margin-bottom: 240px;*/
    margin-bottom: 160px;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $white;
  }
  h2 {
    margin: 0;
    margin-bottom: 100px;
    font-size: 70px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: $white;
    }
  }
  h3 {
    font-size: 45px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $white;
    margin: 200px 150px 70px 0;
  }
}
.introBottomInner {
  display: flex;
  justify-content: space-between;
}
.introBottomItem {
  width: 32%;
  h3 {
    font-family: $title;
    font-size: 34px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: $white;
    height: 50px;
    margin: 0;
    margin-bottom: 60px;
    white-space: nowrap;
    span {
      color: $brandRed;
      font-weight: bold;
    }
  }
  p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $white;
    margin-bottom: 60px;
  }
  a {
    height: 50px;
    display: flex;
    align-items: center;
    span {
      font-family: $title;
      font-size: 24px;
      font-weight: lighter;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $brandGreen;
      text-transform: uppercase;
      margin-right: 30px;
      transition:300ms;
    }
    svg {
      path {
        transition:300ms;
      }
    }
    &:hover {
      span {
        color: $brandRed;
      }
      svg {
        path {
          fill: $brandRed;
        }
      }
    }
  }
}
.services {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 120px;
  padding-bottom: 200px;
  h2 {
    font-size: 50px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    margin: 0 auto 120px auto;
    text-align: center;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      transform:translateX(-50%);
      height: 3px;
      width: 70px;
      background: $white;
    }
  }
}
.customNavigation {
  position: absolute;
  top: 50%;
  transform:translateY(-50%);
  background: transparent;
  border: 0;
  cursor: pointer;
  &--prev {
    left: -50px;
  }
  &--next {
    right: -50px;
  }
  svg {
    path {
      transition: 300ms;
    }
  }
  &:hover {
    svg {
      path {
        fill: $brandGreen !important;
      }
    }
  }
}
.servicesItem {
  width: 24%;
  text-align: center;
  h3 {
    font-size: 26px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
  }
  &:hover {
    .servicesItemImage {
      opacity: 0.8;
    }
  }
}
.servicesItemImage {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 auto 50px auto;
  position: relative;
  transition: 300ms;
  img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:cover;
    left: 0;
  }
}
.teamTop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 280px;
  padding-bottom: 110px;
  h2 {
    margin: 0;
    font-size: 70px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: $white;
    }
  }
}
.teamBottom {
  background: $black;
  text-align: center;
  padding-bottom: 170px;
  position: relative;
  h3 {
    font-family: $title;
    font-size: 20px;
    font-weight: lighter;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 6.7px;
    text-align: center;
    color: $brandGreen;
    margin: 0;
    margin-bottom: 70px;
  }
  h2 {
    font-size: 40px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin: 0;
    margin-bottom: 100px;
  }
  a {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-family: $title;
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $brandRed;
      text-transform: uppercase;
      margin-right: 30px;
      transition:300ms;
    }
    svg {
      path {
        transition:300ms;
      }
    }
    &:hover {
      span {
        color: $brandGreen;
      }
      svg {
        path {
          fill: $brandGreen;
        }
      }
    }
  }
}
.teamBottomImg {
  width: 240px;
  height: 240px;
  border-radius: 100%;
  position: relative;
  transform: translateY(-120px);
  margin: 0 auto 0 auto;
  overflow: hidden;
  img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:cover;
    left: 0;
  }
}


.topProject {
  h2 {
    margin: 0;
    margin-bottom: 100px;
    font-size: 70px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: $white;
    }
  }
  .center {
    display: flex;
    justify-content: space-between;
  }
}
.topProjectLeft, .topProjectRight {
  width: 49%;
}

.topProjectLeft {
  p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $white;
    max-width: 460px;
  }
  a{
    font-family: $title;
    font-size: 40px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
  }
}
.topProjectRight {
  position: relative;
  padding-bottom: 70px;
  img {
    width: 100%;
    /*margin-bottom: -200px;
    margin-top: 100px;*/
  }
}
.topProjectTop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /*padding-top: 280px;*/
  padding-top: 180px;
  position: relative;
}
.topProjectBottom {
  background: $gray;
  padding-top: 50px;
  padding-bottom: 200px;
}
.topProjectBottomCta {
  /*top: -50px;*/
  left: 70%;
  position: relative;
  min-width: 240px;
  height: 100px;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% + 100vw);
    background-image: linear-gradient(90deg, #b71010, #e74c4c);
    z-index: -1;
  }
  a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    font-family: $title;
    text-transform: uppercase;
    padding-left: 30px;
  }
}
.news {
  padding: 140px 0 380px 0;
  position: relative;
  background: $white;
  &::before {
    content: '';
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, #ffffff, #eaeaea);
    opacity: 0.8;
  }
  h2 {
    margin: 0;
    margin-bottom: 120px;
    font-size: 70px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: $black;
    }
  }
}
.newsInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px;
}
.newsItem {
  width: 30%;
  margin-bottom: 80px;
  min-height: 620px;
  h3 {
    font-family: $title;
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    margin: 0;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 30px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: $black;
  }
  &:hover {
    .buttonMore {
      opacity: 1;
    }
  }
}
.newsItemImage {
  height: 360px;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;
  img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:cover;
    left: 0;
  }
}


.buttonMore {
  height: 50px;
  display: flex;
  align-items: center;
  transition:300ms;
  opacity: 0;
  span {
    font-family: $title;
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandRed;
    text-transform: uppercase;
    margin-right: 30px;
    transition:300ms;
  }
  svg {
    path {
      transition:300ms;
    }
  }
  &--alignRight {
    float: right;
    opacity: 1;
    &:hover {
      span {
        color: $brandGreen;
      }
      svg {
        path {
          fill: $brandGreen;
        }
      }
    }
  }
}
.leftTitleTop {
  position: absolute;
  left: 20px;
  top: 160px;
  transform: rotate(-90deg) translateY(-100%);
  z-index: 10000;
  h3 {
    margin: 0;
    font-family: $title;
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    span {
      color: $brandRed;
      font-family: $default;
      font-weight:bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px;
      margin-left:5px;
      margin-right:5px;
    }
  }
  &--black {
    top: 355px;
    h3 {
      color: $black;
    }
  }
}

.leftTitleBottom {
  position: absolute;
  left: 20px;
  bottom: 175px;
  transform: rotate(-90deg) translateY(-100%);
  z-index: 10000;
  h3 {
    margin: 0;
    font-family: $title;
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    span {
      color: $brandRed;
      font-family: $default;
      font-weight:bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px;
      margin-left:5px;
      margin-right:5px;
    }
  }
}

.rightTitleTop {
  position: absolute;
  right: 20px;
  top: 160px;
  transform: rotate(90deg) translateY(100%);
  z-index: 10000;
  h3 {
    margin: 0;
    font-family: $title;
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $brandGreen;
    span {
      color: $brandRed;
      font-family: $default;
      font-weight:bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px;
      margin-left:5px;
      margin-right:5px;
    }
  }
}