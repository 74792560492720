.siteFooterWrap {
  max-width: 1380px;
  margin: 0 auto;
}
.siteFooterInner {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -145px;
  position: relative;
  z-index: 1000;
  margin-bottom: 190px;
}
.siteFooterBox {
  height: 290px;
  background: $black;
  padding-left: 70px;
  padding-top: 50px;
  width: 1160px;
  h3{
    max-width: 400px;
    font-family: $title;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    margin: 0;
    margin-bottom: 30px;
  }
  p {
    max-width: 560px;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $white;
  }
}
.siteFooterCta {
  position: relative;
  width: 400px;
  height: 100px;
  margin-left: -200px;
  background-image: linear-gradient(90deg, #b71010, #e74c4c);
  a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    font-family: $title;
    text-transform: uppercase;
    padding-left: 30px;
  }
}

.copyRight {
  background: $gray;
  .center {
    height: 170px;
    display: flex;
    justify-content: space-between;
  }
}
.copyRightLeft {
  position: relative;
  height: 170px;
  span {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-family: $title;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    b {
      margin:0 10px;
      color: $brandRed;
      font-size: 30px;
      line-height: initial;
      position: relative;
      top: 7px;
    }
  }
}
.copyRightRight {
  position: relative;
  height: 170px;
  a {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: $brandRed;
  }
}

.siteFooterFlex {
  display: flex;
  align-items: center;
  margin-bottom: 110px;
}
.siteFooterLeft {
  width: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h4 {
    font-family: $title;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 5.3px;
    color: $black;
    margin: 0;
    margin-bottom: 10px;
  }
  h3 {
    font-family: $title;
    font-size: 28px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $black;
    margin: 0;
  }
}
.siteFooterLogoBoxWrap {
  width: 60%;
}
.siteFooterLogo {
  background: $black;
  width: 160px;
  top: 0;
  left: 0;
  position: relative;
  display: inline-block;
  height: 120px;
  text-align: center;
  margin-right: 50px;
  svg {
    position: relative;
    max-width: 80%;
    top: 45%;
    transform: translateY(-50%);
  }
  a {
    display: block;
    height: 120px;
  }
}
.siteFooterItem {
  width: 20%;
  h4 {
    font-family: $title;
    font-size: 16px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
    margin: 0;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: -1;
      height: 2px;
      width: 40px;
      background: $brandRed;
    }
  }
  p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: $black;
  }
}