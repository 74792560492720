@charset "UTF-8";
/* Slider */
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,700&subset=latin-ext");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

@font-face {
  font-family: "Bank Gothic";
  src: url("../fonts/BankGothicLtBTLight.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "Bank Gothic";
  src: url("../fonts/BankGothicMdBT.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal; }

* {
  box-sizing: border-box; }

body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  overflow-x: hidden; }

body, html {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400; }
  @media (max-width: 768px) {
    body, html {
      width: 100%;
      height: 100%; } }

#ccc {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important; }

body, html, ul, ol {
  padding: 0;
  margin: 0; }

button {
  padding: 0;
  outline: 0; }

a {
  text-decoration: none; }

body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.center {
  max-width: 1280px;
  margin: 0 auto;
  position: relative; }

.siteFooterWrap {
  max-width: 1380px;
  margin: 0 auto; }

.siteFooterInner {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -145px;
  position: relative;
  z-index: 1000;
  margin-bottom: 190px; }

.siteFooterBox {
  height: 290px;
  background: #000000;
  padding-left: 70px;
  padding-top: 50px;
  width: 1160px; }
  .siteFooterBox h3 {
    max-width: 400px;
    font-family: "Bank Gothic";
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    margin-bottom: 30px; }
  .siteFooterBox p {
    max-width: 560px;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff; }

.siteFooterCta {
  position: relative;
  width: 400px;
  height: 100px;
  margin-left: -200px;
  background-image: linear-gradient(90deg, #b71010, #e74c4c); }
  .siteFooterCta a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    font-family: "Bank Gothic";
    text-transform: uppercase;
    padding-left: 30px; }

.copyRight {
  background: #eaeaea; }
  .copyRight .center {
    height: 170px;
    display: flex;
    justify-content: space-between; }

.copyRightLeft {
  position: relative;
  height: 170px; }
  .copyRightLeft span {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Bank Gothic";
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000; }
    .copyRightLeft span b {
      margin: 0 10px;
      color: #d0021b;
      font-size: 30px;
      line-height: initial;
      position: relative;
      top: 7px; }

.copyRightRight {
  position: relative;
  height: 170px; }
  .copyRightRight a {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #d0021b; }

.siteFooterFlex {
  display: flex;
  align-items: center;
  margin-bottom: 110px; }

.siteFooterLeft {
  width: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .siteFooterLeft h4 {
    font-family: "Bank Gothic";
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 5.3px;
    color: #000000;
    margin: 0;
    margin-bottom: 10px; }
  .siteFooterLeft h3 {
    font-family: "Bank Gothic";
    font-size: 28px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
    margin: 0; }

.siteFooterLogoBoxWrap {
  width: 60%; }

.siteFooterLogo {
  background: #000000;
  width: 160px;
  top: 0;
  left: 0;
  position: relative;
  display: inline-block;
  height: 120px;
  text-align: center;
  margin-right: 50px; }
  .siteFooterLogo svg {
    position: relative;
    max-width: 80%;
    top: 45%;
    transform: translateY(-50%); }
  .siteFooterLogo a {
    display: block;
    height: 120px; }

.siteFooterItem {
  width: 20%; }
  .siteFooterItem h4 {
    font-family: "Bank Gothic";
    font-size: 16px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin: 0;
    margin-bottom: 40px;
    position: relative;
    z-index: 1; }
    .siteFooterItem h4::before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      z-index: -1;
      height: 2px;
      width: 40px;
      background: #d0021b; }
  .siteFooterItem p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: #000000; }

.siteHeader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 120px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  z-index: 100000; }

.siteHeaderLogo {
  background: #000000;
  width: 160px;
  top: 0;
  left: 0;
  z-index: 10;
  position: relative;
  display: block;
  height: 120px;
  text-align: center; }
  .siteHeaderLogo svg {
    position: relative;
    max-width: 130px;
    top: 47%;
    transform: translateY(-50%); }
  .siteHeaderLogo a {
    display: block;
    height: 120px; }

.langSelector {
  width: 160px;
  height: 120px;
  z-index: 10;
  position: relative;
  top: 0;
  right: 0; }
  .langSelector ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .langSelector li {
    display: inline-block; }
  .langSelector .wpml-ls-current-language a {
    color: #d0021b;
    font-size: 18px; }
  .langSelector a {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
    text-transform: uppercase;
    transition: 300ms;
    position: relative; }
    .langSelector a:hover {
      color: #d0021b; }

.mainNav {
  max-width: 1100px;
  width: 100%;
  position: relative;
  text-align: center; }
  .mainNav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .mainNav li {
    display: inline-block; }
    .mainNav li ul {
      position: absolute;
      top: 0;
      transform: translateY(-500px);
      padding-top: 90px;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: opacity 0.4s;
      -moz-transition: opacity 0.4s;
      -o-transition: opacity 0.4s;
      transition: opacity 0.4s;
      text-align: left; }
      .mainNav li ul li {
        display: block;
        margin-bottom: 20px; }
        .mainNav li ul li a {
          font-size: 17px; }
      .mainNav li ul:before {
        content: '';
        position: absolute;
        left: -100vw;
        right: -100vw;
        top: 0;
        min-height: 270px;
        background: #ffffff;
        z-index: -1; }
    .mainNav li:hover ul {
      transform: translateY(0);
      opacity: 1;
      visibility: visible; }
  .mainNav a {
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-transform: uppercase;
    font-family: "Bank Gothic";
    padding-left: 30px;
    padding-right: 30px;
    transition: 300ms;
    z-index: 10;
    position: relative; }
    .mainNav a:hover {
      color: #d0021b; }
  .mainNav .current-menu-item a {
    color: #d0021b; }

.mobileNav {
  display: none; }

.intro  {
  background-color: #000000;
  margin-top: 120px;
  padding-top: 210px;
  /*height: 1700px;*/ }

.videoContainer {
  position: absolute;
  top: 120px;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .videoContainer video {
    width: 100%;
    height: auto; }
  .videoContainer:before {
    content: '';
    position: absolute;
    top: 75%;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%); }

.introTop {
  display: flex;
  padding-bottom: 100px;
  position: relative;
  align-items: center;
  justify-content: center; }
  .introTop h6 {
    font-family: "Bank Gothic";
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 6.7px;
    color: #ffffff; }
    .introTop h6 span {
      color: #d0021b;
      font-family: "Raleway", sans-serif;
      font-weight: bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px; }
  .introTop h2 {
    font-size: 54px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0; }

.introTopInner {
  max-width: 1280px;
  /*width: calc(100% - 240px);*/
  width: 100%;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1024px) {
    .introTopInner {
      display: block; } }

.introTopCta {
  /*bottom: -100px;*/
  top: 225px;
  position: relative;
  min-width: 240px;
  max-width: 240px;
  height: 100px;
  /*align-self: flex-end;*/
  z-index: 1; }
  .introTopCta::before  {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% + 100vw);
    background-image: linear-gradient(90deg, #b71010, #e74c4c);
    z-index: -1; }
  .introTopCta a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    font-family: "Bank Gothic";
    text-transform: uppercase;
    padding-left: 30px; }

.introBottom {
  position: relative; }
  .introBottom .center {
    position: relative;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 90px;
    /*&::before {
      content: '';
      position: absolute;
      top: 0;
      right: 240px;
      bottom: 0;
      width: calc(100% + 100vw);
      background-image: linear-gradient(0deg, rgba(26, 26, 26, 0), #000000);
      z-index: -1;
      opacity: 0.7;
    }*/ }
  .introBottom h4 {
    margin: 0;
    /*margin-bottom: 240px;*/
    margin-bottom: 160px;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff; }
  .introBottom h2 {
    margin: 0;
    margin-bottom: 100px;
    font-size: 70px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    z-index: 1; }
    .introBottom h2::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: #ffffff; }
  .introBottom h3 {
    font-size: 45px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #ffffff;
    margin: 200px 150px 70px 0; }

.introBottomInner {
  display: flex;
  justify-content: space-between; }

.introBottomItem {
  width: 32%; }
  .introBottomItem h3 {
    font-family: "Bank Gothic";
    font-size: 34px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #ffffff;
    height: 50px;
    margin: 0;
    margin-bottom: 60px;
    white-space: nowrap; }
    .introBottomItem h3 span {
      color: #d0021b;
      font-weight: bold; }
  .introBottomItem p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 60px; }
  .introBottomItem a {
    height: 50px;
    display: flex;
    align-items: center; }
    .introBottomItem a span {
      font-family: "Bank Gothic";
      font-size: 24px;
      font-weight: lighter;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #7ed321;
      text-transform: uppercase;
      margin-right: 30px;
      transition: 300ms; }
    .introBottomItem a svg path {
      transition: 300ms; }
    .introBottomItem a:hover span {
      color: #d0021b; }
    .introBottomItem a:hover svg path {
      fill: #d0021b; }

.services {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 120px;
  padding-bottom: 200px; }
  .services h2 {
    font-size: 50px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0 auto 120px auto;
    text-align: center;
    position: relative;
    z-index: 1; }
    .services h2::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      width: 70px;
      background: #ffffff; }

.customNavigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: 0;
  cursor: pointer; }
  .customNavigation--prev {
    left: -50px; }
  .customNavigation--next {
    right: -50px; }
  .customNavigation svg path {
    transition: 300ms; }
  .customNavigation:hover svg path {
    fill: #7ed321 !important; }

.servicesItem {
  width: 24%;
  text-align: center; }
  .servicesItem h3 {
    font-size: 26px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0; }
  .servicesItem:hover .servicesItemImage {
    opacity: 0.8; }

.servicesItemImage {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 auto 50px auto;
  position: relative;
  transition: 300ms; }
  .servicesItemImage img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0; }

.teamTop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 280px;
  padding-bottom: 110px; }
  .teamTop h2 {
    margin: 0;
    font-size: 70px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    z-index: 1; }
    .teamTop h2::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: #ffffff; }

.teamBottom {
  background: #000000;
  text-align: center;
  padding-bottom: 170px;
  position: relative; }
  .teamBottom h3 {
    font-family: "Bank Gothic";
    font-size: 20px;
    font-weight: lighter;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 6.7px;
    text-align: center;
    color: #7ed321;
    margin: 0;
    margin-bottom: 70px; }
  .teamBottom h2 {
    font-size: 40px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
    margin-bottom: 100px; }
  .teamBottom a {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .teamBottom a span {
      font-family: "Bank Gothic";
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d0021b;
      text-transform: uppercase;
      margin-right: 30px;
      transition: 300ms; }
    .teamBottom a svg path {
      transition: 300ms; }
    .teamBottom a:hover span {
      color: #7ed321; }
    .teamBottom a:hover svg path {
      fill: #7ed321; }

.teamBottomImg {
  width: 240px;
  height: 240px;
  border-radius: 100%;
  position: relative;
  transform: translateY(-120px);
  margin: 0 auto 0 auto;
  overflow: hidden; }
  .teamBottomImg img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0; }

.topProject h2 {
  margin: 0;
  margin-bottom: 100px;
  font-size: 70px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  position: relative;
  z-index: 1; }
  .topProject h2::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    height: 3px;
    width: 70px;
    background: #ffffff; }

.topProject .center {
  display: flex;
  justify-content: space-between; }

.topProjectLeft, .topProjectRight {
  width: 49%; }

.topProjectLeft p {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;
  max-width: 460px; }

.topProjectLeft a {
  font-family: "Bank Gothic";
  font-size: 40px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }

.topProjectRight {
  position: relative;
  padding-bottom: 70px; }
  .topProjectRight img {
    width: 100%;
    /*margin-bottom: -200px;
    margin-top: 100px;*/ }

.topProjectTop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /*padding-top: 280px;*/
  padding-top: 180px;
  position: relative; }

.topProjectBottom {
  background: #eaeaea;
  padding-top: 50px;
  padding-bottom: 200px; }

.topProjectBottomCta {
  /*top: -50px;*/
  left: 70%;
  position: relative;
  min-width: 240px;
  height: 100px;
  z-index: 1; }
  .topProjectBottomCta::before  {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% + 100vw);
    background-image: linear-gradient(90deg, #b71010, #e74c4c);
    z-index: -1; }
  .topProjectBottomCta a {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    font-family: "Bank Gothic";
    text-transform: uppercase;
    padding-left: 30px; }

.news {
  padding: 140px 0 380px 0;
  position: relative;
  background: #ffffff; }
  .news::before {
    content: '';
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, #ffffff, #eaeaea);
    opacity: 0.8; }
  .news h2 {
    margin: 0;
    margin-bottom: 120px;
    font-size: 70px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    position: relative;
    z-index: 1; }
    .news h2::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: #000000; }

.newsInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px; }

.newsItem {
  width: 30%;
  margin-bottom: 80px;
  min-height: 620px; }
  .newsItem h3 {
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin: 0;
    margin-bottom: 30px; }
  .newsItem p {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 30px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #000000; }
  .newsItem:hover .buttonMore {
    opacity: 1; }

.newsItemImage {
  height: 360px;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px; }
  .newsItemImage img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0; }

.buttonMore {
  height: 50px;
  display: flex;
  align-items: center;
  transition: 300ms;
  opacity: 0; }
  .buttonMore span {
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
    text-transform: uppercase;
    margin-right: 30px;
    transition: 300ms; }
  .buttonMore svg path {
    transition: 300ms; }
  .buttonMore--alignRight {
    float: right;
    opacity: 1; }
    .buttonMore--alignRight:hover span {
      color: #7ed321; }
    .buttonMore--alignRight:hover svg path {
      fill: #7ed321; }

.leftTitleTop {
  position: absolute;
  left: 20px;
  top: 160px;
  transform: rotate(-90deg) translateY(-100%);
  z-index: 10000; }
  .leftTitleTop h3 {
    margin: 0;
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321; }
    .leftTitleTop h3 span {
      color: #d0021b;
      font-family: "Raleway", sans-serif;
      font-weight: bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px;
      margin-left: 5px;
      margin-right: 5px; }
  .leftTitleTop--black {
    top: 355px; }
    .leftTitleTop--black h3 {
      color: #000000; }

.leftTitleBottom {
  position: absolute;
  left: 20px;
  bottom: 175px;
  transform: rotate(-90deg) translateY(-100%);
  z-index: 10000; }
  .leftTitleBottom h3 {
    margin: 0;
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321; }
    .leftTitleBottom h3 span {
      color: #d0021b;
      font-family: "Raleway", sans-serif;
      font-weight: bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px;
      margin-left: 5px;
      margin-right: 5px; }

.rightTitleTop {
  position: absolute;
  right: 20px;
  top: 160px;
  transform: rotate(90deg) translateY(100%);
  z-index: 10000; }
  .rightTitleTop h3 {
    margin: 0;
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321; }
    .rightTitleTop h3 span {
      color: #d0021b;
      font-family: "Raleway", sans-serif;
      font-weight: bold;
      position: relative;
      top: 1px;
      font-style: normal;
      font-size: 16px;
      margin-left: 5px;
      margin-right: 5px; }

.newsPageIntro {
  padding-top: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .newsPageIntro .center {
    padding: 80px 0;
    z-index: 1;
    height: 320px;
    margin-bottom: -160px; }
    .newsPageIntro .center::before  {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: calc(100% + 100vw);
      background-image: linear-gradient(90deg, #000000, #1a1a1a);
      z-index: -1; }
  .newsPageIntro h2 {
    margin: 0;
    font-family: "Bank Gothic";
    font-size: 64px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #ffffff; }

.paginationWrap {
  width: 100%;
  margin-bottom: 100px;
  height: 50px; }
  .paginationWrap .buttonMore a {
    display: flex;
    align-items: center;
    height: 50px; }
  .paginationWrap .center-load {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
    width: 100%; }

.feedWrap h4.newsFeedTitle {
  margin: 0;
  margin-bottom: 20px;
  font-size: 44px;
  font-family: "Bank Gothic";
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d0021b;
  position: relative;
  z-index: 1; }

.feedWrap .yotu-video-title {
  font-family: "Bank Gothic";
  font-size: 24px !important;
  font-weight: lighter !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000 !important;
  margin: 0 !important;
  margin-bottom: 30px !important; }

.feedWrap .yotu-videos a:hover h3 {
  text-decoration: none !important;
  color: #d0021b !important; }

.yotu-copyright {
  display: none !important; }

.yotu-button-prs {
  height: 80px !important;
  width: 400px !important;
  background-image: linear-gradient(90deg, #b71010, #e74c4c) !important;
  display: block !important;
  position: relative !important;
  font-size: 30px !important;
  font-weight: lighter !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 75px !important;
  text-align: center !important;
  letter-spacing: normal !important;
  color: #ffffff !important;
  font-family: "Bank Gothic" !important;
  text-transform: uppercase !important;
  border: 0 !important;
  margin: 0 auto !important;
  border-radius: 0 !important;
  box-shadow: none !important; }

.pageIntro {
  padding-top: 450px;
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .pageIntro h2 {
    margin: 0;
    font-family: "Bank Gothic";
    font-size: 64px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #ffffff; }
    .pageIntro h2 span {
      color: #d0021b; }

.teamPage {
  padding: 140px 0 310px 0;
  background: #000000;
  position: relative; }
  .teamPage .rightTitleTop {
    top: 150vh; }

.teamLeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 220px; }

.teamLeaderLeft {
  width: 30%; }
  .teamLeaderLeft h4 {
    margin: 0;
    margin-bottom: 40px;
    font-family: "Bank Gothic";
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b; }
  .teamLeaderLeft p {
    max-width: 210px;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #828282;
    transition: 300ms; }
  .teamLeaderLeft a {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #828282;
    transition: 300ms; }
    .teamLeaderLeft a:hover {
      color: #7ed321; }

.teamLeaderRight {
  width: 65%; }
  .teamLeaderRight h2 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 44px;
    font-family: "Bank Gothic";
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    z-index: 1; }
    .teamLeaderRight h2::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      height: 3px;
      width: 70px;
      background: #ffffff; }
  .teamLeaderRight h6 {
    font-size: 26px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0;
    margin-bottom: 120px; }
  .teamLeaderRight h5 {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0;
    transition: 300ms; }
    .teamLeaderRight h5 a {
      text-decoration: none;
      color: #7ed321;
      transition: 300ms; }
    .teamLeaderRight h5:hover a {
      color: #ffffff; }
  .teamLeaderRight p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #ffffff; }

.teamLeaderImg {
  width: 210px;
  height: 210px;
  border-radius: 100%;
  position: relative;
  margin-bottom: 80px;
  overflow: hidden; }
  .teamLeaderImg img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0; }

.people {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.person {
  width: 30%;
  padding: 30px;
  background-image: linear-gradient(329deg, #000000, #1a1a1a);
  margin-bottom: 190px; }
  .person h3 {
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .person h6 {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0;
    margin-bottom: 30px; }
  .person p {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 40px; }
    .person p:last-of-type {
      margin-bottom: 0; }
  .person a {
    color: #828282;
    transition: 300ms; }
    .person a:hover {
      color: #7ed321; }
  .person span {
    color: #828282; }
  .person h5 {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    margin: 0;
    transition: 300ms; }
    .person h5 a {
      text-decoration: none;
      color: #7ed321; }
    .person h5:hover a {
      color: #ffffff; }

.personImg {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  position: relative;
  border: 1px solid #ffffff;
  overflow: hidden;
  margin-top: -90px;
  margin-bottom: 30px; }
  .personImg img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0; }

.referencePage .leftTitleTop {
  top: 210px; }

.references {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between; }

.referencesFilter {
  margin-bottom: 80px;
  border-bottom: 1px solid #ffffff; }
  .referencesFilter li {
    padding-bottom: 40px; }
  .referencesFilter span {
    font-family: "Bank Gothic";
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444444;
    cursor: pointer;
    transition: 300ms;
    padding-bottom: 40px;
    position: relative;
    white-space: nowrap; }
    .referencesFilter span:before {
      content: '';
      position: absolute;
      bottom: -1.5px;
      left: 0;
      right: 0;
      height: 3px;
      background: #7ed321;
      opacity: 0;
      transition: 300ms; }
    .referencesFilter span:hover {
      color: #7ed321; }
      .referencesFilter span:hover:before {
        opacity: 1; }
  .referencesFilter .slick-list {
    overflow: visible !important; }
  .referencesFilter .slick-track {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 !important; }
    .referencesFilter .slick-track::before {
      display: none !important; }
  .referencesFilter .slick-slide {
    max-width: auto !important; }
  .referencesFilter .slick-current span {
    color: #7ed321; }
    .referencesFilter .slick-current span:before {
      opacity: 1; }

.reference {
  width: 30%;
  padding: 30px;
  background-image: linear-gradient(329deg, #000000, #1a1a1a);
  margin-bottom: 90px;
  min-height: 170px; }
  .reference h3 {
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
    transition: 300ms; }
  .reference:hover h3 {
    color: #7ed321; }

.clients {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 80px;
  border-top: 1px solid #ffffff; }

.client {
  width: 23%;
  padding: 30px;
  background-image: linear-gradient(329deg, #000000, #1a1a1a);
  margin-bottom: 2%;
  height: 130px;
  position: relative;
  /*&:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: $brandRed;
    border-radius: 100%;
    right: -25px;
    top: 50%;
    transform:translateY(-50%);
  }
  &:nth-of-type(4n) {
    &:before {
      display: none;
    }
  }*/ }
  .client h3 {
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
    transition: 300ms; }
  .client:hover h3 {
    color: #7ed321; }

.similarReferences h2 {
  font-family: "Bank Gothic";
  margin: 0;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7ed321; }

.similarReferences .references {
  padding: 50px 0;
  border: 1px solid #ffffff;
  border-left: 0;
  border-right: 0;
  margin-bottom: 80px; }

.similarReferences .moreProjects {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .similarReferences .moreProjects span {
    font-family: "Bank Gothic";
    font-size: 24px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
    text-transform: uppercase;
    margin-right: 30px;
    transition: 300ms; }
  .similarReferences .moreProjects svg path {
    transition: 300ms; }
  .similarReferences .moreProjects:hover span {
    color: #7ed321; }
  .similarReferences .moreProjects:hover svg path {
    fill: #7ed321; }

.singleProjectPage {
  background: #000000;
  position: relative;
  padding-bottom: 400px; }
  .singleProjectPage .leftTitleTop {
    top: 300px; }

.singleProject {
  display: flex;
  justify-content: space-between;
  margin-bottom: 140px; }

.singleProjectLeft, .singleProjectRight {
  width: 45%; }

.singleProjectLeft {
  padding-top: 90px; }
  .singleProjectLeft h4 {
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #ffffff; }
  .singleProjectLeft p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #ffffff; }
  .singleProjectLeft ul {
    list-style: none; }
  .singleProjectLeft li {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #ffffff; }
  .singleProjectLeft a {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #d0021b;
    transition: 300ms; }
    .singleProjectLeft a:hover {
      color: #7ed321; }

.singleProjectRight {
  padding: 90px;
  padding-right: 0;
  z-index: 1;
  position: relative; }
  .singleProjectRight::before  {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% + 100vw);
    background-image: linear-gradient(314deg, #000000, #1a1a1a);
    z-index: -1; }
  .singleProjectRight img {
    vertical-align: top;
    max-width: 100%;
    margin-bottom: 40px; }
  .singleProjectRight a {
    font-family: "Bank Gothic";
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0021b;
    transition: 300ms; }
    .singleProjectRight a:hover {
      color: #7ed321; }

.map {
  height: 90vh;
  width: 55%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000; }
  .map::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
    z-index: 1; }

.contactPageSection .singleProjectLeft, .contactPageSection .singleProjectRight {
  height: 90vh;
  background: transparent; }

.redButton {
  align-self: flex-end;
  height: 80px;
  width: 400px;
  background-image: linear-gradient(90deg, #b71010, #e74c4c);
  display: block;
  position: relative;
  font-size: 30px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: 75px;
  text-align: center;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "Bank Gothic";
  text-transform: uppercase;
  margin: 50px auto 0 auto;
  padding: 0 30px; }

.buttonsList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 40px 0 0 0; }
  .buttonsList li {
    margin: 0 1% 2% 1%; }
  .buttonsList .redButton {
    width: 100%;
    align-self: initial;
    margin: 0; }

.defaultPageIntro {
  padding-top: 450px;
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .defaultPageIntro .center {
    display: flex;
    justify-content: space-between; }
  .defaultPageIntro h2 {
    margin: 0;
    font-family: "Bank Gothic";
    font-size: 64px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #ffffff; }
  .defaultPageIntro a {
    margin: 0;
    top: -20px; }

.defaultPageSection {
  padding-bottom: 500px; }

.contactPageSection {
  padding-bottom: 150px; }

.relatedServices {
  margin-bottom: 160px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .relatedServices h2 {
    font-family: "Bank Gothic";
    margin: 0;
    margin-bottom: 150px;
    font-size: 30px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7ed321;
    width: 100%; }

.relatedService {
  width: 47%;
  display: flex;
  justify-content: space-between; }

.relatedServiceImg {
  margin-top: -75px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: relative;
  overflow: hidden; }
  .relatedServiceImg img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0; }

.relatedServices.companies .relatedService,
.relatedServices.tvrtke .relatedService {
  width: 31%; }

.relatedServices.companies .relatedServiceImg,
.relatedServices.tvrtke .relatedServiceImg {
  margin-top: -35px;
  width: 100px;
  height: 100px; }

.relatedServiceCnt {
  width: 70%; }
  .relatedServiceCnt h3 {
    margin: 0;
    font-size: 26px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  .relatedServiceCnt p {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.78;
    letter-spacing: normal;
    color: #ffffff; }

.relatedNews {
  background: transparent;
  margin-top: -430px; }
  .relatedNews::before {
    display: none; }

.relatedNewsTitle {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000; }

.postContent {
  background: #eeeeee;
  padding-bottom: 500px; }
  .postContent .center {
    display: flex;
    justify-content: space-between; }

.postContentLeft {
  background: #ffffff;
  padding: 100px 60px;
  width: 55%; }
  .postContentLeft__Title {
    margin: 0 0 30px 0;
    font-family: "Bank Gothic";
    font-size: 44px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000000; }
    .postContentLeft__Title span {
      color: #d0021b; }
  .postContentLeft p {
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000; }
  .postContentLeft img {
    vertical-align: middle;
    width: calc(100% + 160px);
    height: auto;
    margin-left: -80px; }

.postContentRight {
  padding-top: 125px;
  width: 35%; }

.postAuthor h3 {
  font-family: "Bank Gothic";
  font-size: 24px;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
  margin-bottom: 10px; }

.postAuthor h5 {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d0021b;
  margin: 0;
  margin-bottom: 60px; }

.postAuthor h6 {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  margin-bottom: 20px; }

.postAuthor ul  {
  list-style: none;
  padding: 0;
  margin: 0; }

.postAuthor li {
  display: inline-block;
  margin-right: 5px; }

.postAuthorImg {
  width: 170px;
  height: 170px;
  border-radius: 100%;
  position: relative;
  margin-bottom: 50px;
  overflow: hidden; }
  .postAuthorImg img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0; }
